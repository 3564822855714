import React from 'react';
import ContactSection from '../../sections/ContactSection/ContactSection';
import GoBackHeader from '../../organisms/GoBackHeader';
import { SupportTicketTypes } from '../../../utilities/interfaces';

const DeleteAccount = () => {
    return (
        <>
            <GoBackHeader />
            <ContactSection type={SupportTicketTypes.DELETE_ACCOUNT} />
        </>
    );
};

export default DeleteAccount;
