import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    AppleStoreBadge,
    GoogleStoreBadge,
    LogoFilled,
    TextLogo
} from '../../../assets/images';
import './Room.css';
import { isIOS, isMacOs } from 'react-device-detect';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Alert, Button, Collapse, IconButton } from '@mui/material';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import {
    RegExpMatcher,
    TextCensor,
    englishDataset,
    englishRecommendedTransformers
} from 'obscenity';

interface RoomData {
    topic: string;
}

const { REACT_APP_IOS_STORE_URL = '', REACT_APP_ANDROID_STORE_URL = '' } =
    process.env;

const Room = () => {
    const { room } = useParams();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { REACT_APP_PUBLIC_ROOM_URL } = process.env;
    const [data, setData] = useState<RoomData | null>(null);
    const [topBids, setTopBids] = useState<number[] | null>(null);
    const [roomError, setRoomError] = useState(false);
    const [questionError, setQuestionError] = useState(false);
    const [questionErrorMessage, setQuestionErrorMessage] = useState(
        'There was an error sending your question. Please try again.'
    );
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [isOpen, setOpen] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const isRecaptchaAvailable = useMemo(
        () => !!executeRecaptcha,
        [executeRecaptcha]
    );

    const matcher = new RegExpMatcher({
        ...englishDataset.build(),
        ...englishRecommendedTransformers
    });

    const censor = new TextCensor();

    const isButtonDisabled =
        roomError || !question || question === '' || !executeRecaptcha;

    const resetQuestion = () => {
        setQuestionError(false);
        setAlertOpen(false);
        setQuestion('');
        if (textareaRef.current) textareaRef.current.style.height = '59px';
    };

    const sendQuestion = async (question: string, recaptchaToken: string) => {
        if (question && room) {
            await axios
                .post(`${REACT_APP_PUBLIC_ROOM_URL}/questions/create`, {
                    roomId: room,
                    question,
                    recaptchaToken
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === 403) {
                            setQuestionErrorMessage(res.data.message);
                            setQuestionError(true);
                            setAlertOpen(true);
                            return;
                        }

                        resetQuestion();
                        setOpen(true);
                        return;
                    }

                    setQuestionErrorMessage(
                        'There was an error sending your question. Please try again.'
                    );
                    setQuestionError(true);
                    setAlertOpen(true);
                })
                .catch((err) => {
                    console.log(err);
                    setQuestionError(true);
                    setAlertOpen(true);
                });
        }
    };

    const handleClose = ({ reason = '' }) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const getRecaptchaToken = useCallback(
        async (event: string) => {
            if (!isRecaptchaAvailable) {
                console.log('Recaptcha not available');
                return;
            }
            return await executeRecaptcha?.(event);
        },
        [executeRecaptcha, isRecaptchaAvailable]
    );

    const handleGoHome = () => navigate('/');

    const handleQuestionSubmission = async () => {
        const recaptchaToken = await getRecaptchaToken('sendQuestion');

        if (recaptchaToken) {
            sendQuestion(
                censor.applyTo(question, matcher.getAllMatches(question)),
                recaptchaToken
            );
        }
    };

    const handleKeyDown = (e: any) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    useEffect(() => {
        isRecaptchaAvailable &&
            getRecaptchaToken?.('getRoom').then((recaptchaToken) => {
                if (room && recaptchaToken) {
                    axios
                        .get(
                            `${REACT_APP_PUBLIC_ROOM_URL}/rooms/${room}?recaptchaToken=${recaptchaToken}`
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                setRoomError(false);
                                setAlertOpen(false);

                                setData(res.data.room);
                                if (res.data?.topBids?.length > 0)
                                    setTopBids(res.data.topBids.slice(0, 3));
                                return;
                            }
                            setRoomError(true);
                            setAlertOpen(true);
                        })
                        .catch((error) => {
                            console.log(error);
                            setRoomError(true);
                            setAlertOpen(true);
                        });
                }
            });
    }, [
        REACT_APP_PUBLIC_ROOM_URL,
        room,
        isRecaptchaAvailable,
        getRecaptchaToken
    ]);

    return (
        <div className={'container'}>
            <div className="headerContainer">
                <IconButton
                    color="inherit"
                    size="small"
                    id="roomHomeIcon"
                    onClick={handleGoHome}
                >
                    <ArrowBackIcon />
                </IconButton>
                <img alt="whispr-logo" src={TextLogo} className={'logo'} />
            </div>
            <div className={'questionHeader'}>
                <p className={'questionHeaderText'}>{data?.topic}</p>
            </div>
            <div className={'questionContainer'}>
                <textarea
                    ref={textareaRef}
                    className={'questionInput'}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder={'You can write your thoughts here...'}
                    onKeyDown={handleKeyDown}
                    maxLength={700}
                />
                <button
                    className={`${
                        isButtonDisabled
                            ? 'disabledSubmitButton'
                            : 'enabledSubmitButton'
                    } submitQuestionButton`}
                    onClick={() => handleQuestionSubmission()}
                    disabled={isButtonDisabled}
                >
                    Send
                </button>
                <span className="secondary-text">
                    {' '}
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a
                        href="https://policies.google.com/privacy"
                        className="text-link"
                    >
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://policies.google.com/terms"
                        className="text-link"
                    >
                        Terms of Service
                    </a>{' '}
                    apply.
                </span>
                <span className="secondary-text">
                    By continuing, you agree to Whispr's{' '}
                    <Link className="text-link" to="/terms">
                        Terms of Use & Service
                    </Link>{' '}
                    .
                </span>
            </div>
            {topBids?.length ? (
                <>
                    <div className={'topUsedBoostDisplayHeader'}>
                        <p className={'topUsedBoostDisplayHeaderText'}>
                            Top Whisps used on this topic so far
                        </p>
                    </div>
                    <div className="bidRowsContainer">
                        {topBids.map((item, index) => {
                            return (
                                item > 0 && (
                                    <div
                                        className="topBidRow"
                                        key={`${item}-${index}`}
                                    >
                                        <EmojiEventsIcon
                                            fontSize="small"
                                            sx={{
                                                color:
                                                    index === 0
                                                        ? 'gold'
                                                        : index === 1
                                                        ? 'silver'
                                                        : '#CD7F32'
                                            }}
                                        />
                                        <p className="topUsedBoostOrderNumber">
                                            {index + 1}.
                                        </p>
                                        <p
                                            className={
                                                'topUsedBoostDisplayEntry'
                                            }
                                        >{`${item} Whisps`}</p>
                                    </div>
                                )
                            );
                        })}
                    </div>
                </>
            ) : (
                <></>
            )}
            <div className="storeContainer">
                <a
                    className="openButton"
                    href={`whispr://AskAQuestion/${room}`}
                >
                    <img src={LogoFilled} alt="WhisprLogoFilled" width={30} />{' '}
                    Open Whispr Mobile App
                </a>
                <p className="optionText">OR</p>
                <div className="buttonsContainer">
                    {isIOS || isMacOs ? (
                        <a href={REACT_APP_IOS_STORE_URL} className="storeLink">
                            <img
                                alt="apple-store"
                                src={AppleStoreBadge}
                                className={'storeImage'}
                            />
                        </a>
                    ) : (
                        <a
                            href={REACT_APP_ANDROID_STORE_URL}
                            className="storeLink"
                        >
                            <img
                                alt="google-store"
                                src={GoogleStoreBadge}
                                className={'storeImage'}
                            />
                        </a>
                    )}
                </div>
            </div>
            <Modal
                open={isOpen}
                onClose={(_, reason) => handleClose({ reason })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <div className="modalBox">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Great question! 🎉
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Your question has been submitted successfully!
                    </Typography>
                    <div className="modalButtonsContainer">
                        <Button
                            id="questionModalLeftButton"
                            variant="text"
                            onClick={handleGoHome}
                        >
                            Go home
                        </Button>
                        <Button
                            id="questionModalRightButton"
                            className={
                                'enabledSubmitButton submitQuestionButton '
                            }
                            variant="contained"
                            endIcon={<SentimentVerySatisfiedIcon />}
                            onClick={() => handleClose({})}
                        >
                            Ask another question
                        </Button>
                    </div>
                </div>
            </Modal>

            <Collapse in={isAlertOpen}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {roomError
                        ? 'There was an error getting your current room information. Please try accessing your room link again.'
                        : questionError
                        ? questionErrorMessage
                        : ''}
                </Alert>
            </Collapse>
        </div>
    );
};

export default Room;
