export const termsAndConditions = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"Trebuchet MS";
	panose-1:2 11 6 3 2 2 2 2 2 4;}
@font-face
	{font-family:"Trebuchet MS Bold";
	panose-1:2 11 7 3 2 2 2 2 2 4;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-align:justify;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:.25in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:28.35pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:13.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h1.CxSpFirst
	{mso-style-link:"Heading 1 Char";
	margin-top:.25in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:28.35pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:13.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h1.CxSpMiddle
	{mso-style-link:"Heading 1 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:28.35pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:13.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h1.CxSpLast
	{mso-style-link:"Heading 1 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:28.35pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:13.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:56.7pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h2.CxSpFirst
	{mso-style-link:"Heading 2 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:56.7pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h2.CxSpMiddle
	{mso-style-link:"Heading 2 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:56.7pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h2.CxSpLast
	{mso-style-link:"Heading 2 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:56.7pt;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
h3
	{mso-style-link:"Heading 3 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:85.05pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h3.CxSpFirst
	{mso-style-link:"Heading 3 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:85.05pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h3.CxSpMiddle
	{mso-style-link:"Heading 3 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:85.05pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h3.CxSpLast
	{mso-style-link:"Heading 3 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:85.05pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h4
	{mso-style-link:"Heading 4 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:113.4pt;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h4.CxSpFirst
	{mso-style-link:"Heading 4 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:113.4pt;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h4.CxSpMiddle
	{mso-style-link:"Heading 4 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:113.4pt;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h4.CxSpLast
	{mso-style-link:"Heading 4 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:113.4pt;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
h5
	{mso-style-link:"Heading 5 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:141.75pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:#595959;}
h5.CxSpFirst
	{mso-style-link:"Heading 5 Char";
	margin-top:12.0pt;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:141.75pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:#595959;}
h5.CxSpMiddle
	{mso-style-link:"Heading 5 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:141.75pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:#595959;}
h5.CxSpLast
	{mso-style-link:"Heading 5 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:141.75pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:#595959;}
h6
	{mso-style-link:"Heading 6 Char";
	margin-top:4.0pt;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:170.1pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
h6.CxSpFirst
	{mso-style-link:"Heading 6 Char";
	margin-top:4.0pt;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:170.1pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
h6.CxSpMiddle
	{mso-style-link:"Heading 6 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:170.1pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
h6.CxSpLast
	{mso-style-link:"Heading 6 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:170.1pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-link:"Heading 7 Char";
	margin-top:4.0pt;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:198.45pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
p.MsoHeading7CxSpFirst, li.MsoHeading7CxSpFirst, div.MsoHeading7CxSpFirst
	{mso-style-link:"Heading 7 Char";
	margin-top:4.0pt;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:198.45pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
p.MsoHeading7CxSpMiddle, li.MsoHeading7CxSpMiddle, div.MsoHeading7CxSpMiddle
	{mso-style-link:"Heading 7 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:0in;
	margin-left:198.45pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
p.MsoHeading7CxSpLast, li.MsoHeading7CxSpLast, div.MsoHeading7CxSpLast
	{mso-style-link:"Heading 7 Char";
	margin-top:0in;
	margin-right:56.7pt;
	margin-bottom:4.0pt;
	margin-left:198.45pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	color:gray;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-link:"Heading 8 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:3.15in;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	font-style:italic;}
p.MsoHeading8CxSpFirst, li.MsoHeading8CxSpFirst, div.MsoHeading8CxSpFirst
	{mso-style-link:"Heading 8 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:3.15in;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	font-style:italic;}
p.MsoHeading8CxSpMiddle, li.MsoHeading8CxSpMiddle, div.MsoHeading8CxSpMiddle
	{mso-style-link:"Heading 8 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:3.15in;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	font-style:italic;}
p.MsoHeading8CxSpLast, li.MsoHeading8CxSpLast, div.MsoHeading8CxSpLast
	{mso-style-link:"Heading 8 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:3.15in;
	text-align:justify;
	text-indent:-28.3pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;
	font-style:italic;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-link:"Heading 9 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:255.15pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
p.MsoHeading9CxSpFirst, li.MsoHeading9CxSpFirst, div.MsoHeading9CxSpFirst
	{mso-style-link:"Heading 9 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:255.15pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
p.MsoHeading9CxSpMiddle, li.MsoHeading9CxSpMiddle, div.MsoHeading9CxSpMiddle
	{mso-style-link:"Heading 9 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:255.15pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
p.MsoHeading9CxSpLast, li.MsoHeading9CxSpLast, div.MsoHeading9CxSpLast
	{mso-style-link:"Heading 9 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:255.15pt;
	text-align:justify;
	text-indent:-28.35pt;
	line-height:16.0pt;
	font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{mso-style-link:"Title Char";
	margin-top:48.0pt;
	margin-right:56.7pt;
	margin-bottom:12.0pt;
	margin-left:0in;
	line-height:16.0pt;
	page-break-before:always;
	page-break-after:avoid;
	font-size:18.0pt;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{mso-style-link:"Subtitle Char";
	margin-top:16.0pt;
	margin-right:56.7pt;
	margin-bottom:16.0pt;
	margin-left:0in;
	line-height:16.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Trebuchet MS",sans-serif;
	font-variant:small-caps;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
span.SubtitleChar
	{mso-style-name:"Subtitle Char";
	mso-style-link:Subtitle;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;}
span.TitleChar
	{mso-style-name:"Title Char";
	mso-style-link:Title;
	font-family:"Trebuchet MS",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
.MsoChpDefault
	{font-size:10.0pt;
	font-family:"Trebuchet MS",sans-serif;}
.MsoPapDefault
	{text-align:justify;
	line-height:133%;}
 /* Page Definitions */
 @page WordSection1
	{size:594.95pt 841.9pt;
	margin:113.4pt 56.3pt 56.7pt 85.05pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:594.95pt 841.9pt;
	margin:113.4pt 56.3pt 56.7pt 85.05pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:594.95pt 841.9pt;
	margin:113.4pt 56.3pt 56.7pt 85.05pt;}
div.WordSection3
	{page:WordSection3;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=left style='margin-top:6.0pt;text-align:left'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:6.0pt;text-align:left'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:#4472C4'>WHISPR</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:18.0pt;font-family:"Cambria",serif'>TERMS OF USE
AND SERVICE</span></p>
<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>
<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>
<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>
<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:28.0pt;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

</div>

<span lang=EN-GB style='font-size:18.0pt;font-family:"Cambria",serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection2>

<p class=MsoTitle align=center style='text-align:center'><span lang=EN-GB
style='font-size:15.0pt;font-family:"Cambria",serif'>WHISPR</span></p>

<p class=MsoSubtitle align=center style='text-align:center'><b><span
lang=EN-GB style='font-size:13.0pt;font-family:"Cambria",serif'>TERMS OF USE
AND SERVICE</span></b></p>

<p class=MsoNormal align=right style='text-align:right'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>22 August 2024</span></p>

<h1 style='margin-top:2.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>Welcome to Whispr and thank you for
using our platform and services!</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>This document (“</span><span
lang=EN-GB style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none'>Terms of Use and Service</span><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>” or “</span><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none'>Agreement</span><span lang=EN-GB style='font-size:11.0pt;
line-height:150%;font-family:"Cambria",serif;text-transform:none;font-weight:
normal'>”) is a legally binding agreement between you (“</span><span
lang=EN-GB style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none'>User</span><span lang=EN-GB style='font-size:11.0pt;
line-height:150%;font-family:"Cambria",serif;text-transform:none;font-weight:
normal'>” or “</span><span lang=EN-GB style='font-size:11.0pt;line-height:150%;
font-family:"Cambria",serif;text-transform:none'>Customer</span><span
lang=EN-GB style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>”) and CODEFRAME S.R.L. (“</span><span
lang=EN-GB style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none'>CODEFRAME</span><span lang=EN-GB style='font-size:11.0pt;
line-height:150%;font-family:"Cambria",serif;text-transform:none;font-weight:
normal'>”) (collectively, the &quot;</span><span lang=EN-GB style='font-size:
11.0pt;line-height:150%;font-family:"Cambria",serif;text-transform:none'>Parties</span><span
lang=EN-GB style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>&quot; and each, a &quot;</span><span
lang=EN-GB style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none'>Party</span><span lang=EN-GB style='font-size:11.0pt;
line-height:150%;font-family:"Cambria",serif;text-transform:none;font-weight:
normal'>&quot;). The agreement describes the terms and conditions under which
the Customers are enabled to access and use the Whispr platform/application,
available at https://www.whispr.online (“</span><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none'>Whispr</span><span lang=EN-GB style='font-size:11.0pt;
line-height:150%;font-family:"Cambria",serif;text-transform:none;font-weight:
normal'>”) and the services and technology solutions associated with Whispr and
other complementary services made available by CODEFRAME and related to Whispr.</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><a
name="_heading=h.1fob9te"></a><span lang=EN-GB style='font-size:11.0pt;
line-height:150%;font-family:"Cambria",serif;text-transform:none;font-weight:
normal'>Please read these Terms of Use and Service carefully before using
Whispr and the associated services. Please note that additional terms may apply
with respect to any or some of these services.</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>If you do not agree to this Agreement,
please do not use Whispr or associated services.</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>In order to use Whispr and/or the
services, you must be legally permitted to accept the Terms of Use and Service.</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>If you represent an organisation, you
represent and warrant that you are authorised to agree to this Agreement on
behalf of your organisation. In this case, &quot;you&quot; and &quot;your&quot;
will refer to that organisation.</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>Where the context so permits, the words
&quot;we&quot;, &quot;our&quot; and &quot;us&quot; refer to CODEFRAME,
including its Affiliates (as defined below), successors and assigns.</span></h1>

<h1 style='margin-top:6.0pt;margin-right:57.0pt;margin-bottom:0in;margin-left:
28.05pt;text-align:justify;text-indent:0in;line-height:150%'><span lang=EN-GB
style='font-size:11.0pt;line-height:150%;font-family:"Cambria",serif;
text-transform:none;font-weight:normal'>We may amend these Terms of Use and
Service from time to time and if you continue to use Whispr or the associated
services you agree with the updated Terms of Use and Services.  When we are
updating the Terms of Use and Service, a new date will be added at the top of
this document. </span></h1>

<p class=MsoNormal style='margin-top:6.0pt;line-height:115%'><span lang=EN-GB
style='font-family:"Cambria",serif'>&nbsp;</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>I.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>SCOPE
</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.35pt;margin-bottom:.0001pt;text-indent:-28.35pt'><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>1.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>This
Agreement governs the access and use of the Whispr application/platform and of
the associated services, including applications, software owned or operated by
CODEFRAME (“<b>Whispr Apps and Services</b>”).           </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.35pt;margin-bottom:.0001pt;text-indent:-28.35pt'><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>1.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>The
Whispr Apps and Services may include features or services that have separate
rules specific to the feature or service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.35pt;margin-bottom:.0001pt;text-indent:-28.35pt'><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>1.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>We
may provide some or all elements of the Whispr Apps and Services through third
party service providers.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.1pt;margin-bottom:.0001pt;text-indent:-28.35pt'><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>1.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>In
order to use the Whispr Apps and Services, you shall comply with all laws,
rules and regulations applicable to the use of the Whispr Apps and Services and
any additional feature or service you use.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.35pt;margin-bottom:.0001pt;text-indent:-28.35pt'><a
name="_heading=h.3znysh7"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
is constantly in search for ways to improve the quality of the Whispr Apps and
Services, and for this purpose technical configurations, applications,
features, functionalities, infrastructure, security etc. may suffer amendments
or be updated from time to time during your usage of the Whispr Apps and
Services (as defined below). These changes may be made without advance notice
to Customers. However, CODEFRAME will try to operate these changes in a manner
which will not result in a material reduction in the level of performance or
availability of the Whispr Apps and Services during the Usage Period (as
defined below). </span></p>

<h1 style='margin-top:.25in;margin-right:56.9pt;margin-bottom:4.0pt;margin-left:
.4in'><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>II.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>YOUR
USAGE OF Whispr Apps and Services</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>As
a Customer, you may use and procure Whispr Apps and Services by accessing the
Whispr Apps and Services and, when any of the features of the Whispr Apps and
Services are provided by us against a payment, by purchasing the Whispr Apps
and Services through an authorised sales channel in accordance with the terms
and conditions of this Agreement. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
may also offer to Customers the possibility to access and use for free any or
all of the features and functionalities included in Whispr Apps and Services.
In this event, certain features or functionalities may not be available or may
be limited. The period of Free Trial Subscription may differ from one Cloud
Service to another. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>The
access and usage of the Whispr Apps and Services may require the logging in to
the Whispr Apps and Services through a Google/Facebook/Apple account or any
other method for logging in to the Whispr Apps and Services made available from
time to time. Certain features and/or functionalities of the Whispr Apps and
Services may be made available by us by means of subscription (“<b>Subscription</b>”)
and may also require a subscription commitment to a minimum subscription period
(“<b>Minimum Subscription Period</b>”). Where applicable, you will be informed
with respect to the Minimum Subscription Period at the time of your
Subscription.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>When
subscribing to a paid service, Customer will agree with CODEFRAME the period of
the Subscription (&quot;<b>Subscription Term</b>&quot;).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.1pt;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Access
and/or use of Whispr Apps and Services may require password-based access. In
this case, Customer is solely responsible for keeping user ID and password
information confidential and for carrying out any and all actions related to
the Customer’s accounts and passwords.</span><span lang=EN-GB> </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.1pt;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.2et92p0"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>2.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
reserves the right to limit or suspend immediately, in whole or in part,
Customer’s access or use of the Whispr Apps and Services if CODEFRAME is
requested or directed to do so by any competent court of law, government
authority, public agency, or law enforcement agency, or if there are reasonable
grounds to believe that Customer breaches provisions of this Agreement or
accesses or uses the Whispr Apps and Services in a manner that is unlawful,
fraudulent, abusive, or that otherwise violates the applicable laws, or creates
legal risk for CODEFRAME or presents a threat to the security of the Whispr
Apps and Services or other Customers. No discount, credit note or refund shall
be provided in respect of the period during which the Whispr Apps and Services
are unavailable as a result of any limitation or suspension, provided that such
suspension is carried out in accordance with these Terms of Use and Service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.1pt;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.ke30hfgf9e0r"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>2.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>The
Services are not available to, and shall not be accessed or used by individuals
under the age of 18. BY ACCESSING OR USING THE SERVICES, YOU REPRESENT AND
WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.1pt;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.vtfpfprytvp0"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>2.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>The
Services are also not available to, and shall not be accessed or used by, any
users previously blocked or otherwise banned from accessing or using the
Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:28.1pt;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.zf87kz1q0v4d"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>2.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>You
are solely responsible for your interaction with other users of the Services
and other parties that you come in contact with through the Services. To the
fullest extent permitted by applicable law, CODEFRAME hereby disclaims any and
all liability to you or any third party relating to your use of the Services.
You acknowledge and agree that CODEFRAME does not have any special relationship
with you as an end user, and as such, CODEFRAME does not owe you any duty to
protect you from the acts of other users or other third parties.</span></p>

<h1 style='margin-top:.25in;margin-right:56.9pt;margin-bottom:4.0pt;margin-left:
.4in'><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>III.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>FEES</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>If
you subscribe to a paid feature and/or functionality of the Whispr Apps and
Services, you agree to pay the fees (&quot;<b>Fees</b>&quot;) to CODEFRAME as
quoted to you when you purchase that feature and/or functionality of the Whispr
Apps and Services.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>The
Fees will be exclusive of all charges, taxes, VAT (if applicable) and any
government or other taxes, duties, imposts or levies. Unless agreed otherwise
by the Parties, all Fees payable under this Agreement shall be paid into a bank
account notified to the Customer by CODEFRAME or through any other payment
methods made available by CODEFRAME.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
shall make all payments under this Agreement without withholding or deduction
of, or in respect of, any tax unless required by applicable law. If any such withholding
or deduction is required by applicable law, the Customer shall, when making the
payment to which the withholding or deduction relates, pay to CODEFRAME such
additional amount as will ensure that CODEFRAME receives the same total amount
that it would have received if no such withholding or deduction had been
required.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
will not bear liability or responsibility with respect to any tax consequences
to Customers arising from the purchase of the Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.tyjcwt"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>If
Customer fails to timely pay any amount owed to CODEFRAME, CODEFRAME may
suspend the access or use of the Whispr Apps and Services or restrict their
functionalities. CODEFRAME will be entitled to suspend the access and/or usage
of the Whispr Apps and Services by giving not less than 24 hours’ notice
(e-mail shall suffice) in writing of such suspension. No discount, credit note
or refund shall be provided in respect of the period during which the Whispr
Apps and Services are unavailable as a result of any suspension, provided that
such suspension is carried out in accordance with these Terms of Use and
Service.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>2.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Specific
terms related to payment, payment term and payment modalities may be applicable
and such terms will be made available by CODEFRAME to Customers before Customer
purchases a feature and/or functionality and/or Subscription of/to any of the
Whispr Apps and Services. </span></p>

<h1 style='margin-top:.25in;margin-right:56.9pt;margin-bottom:4.0pt;margin-left:
.4in'><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>IV.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CUSTOMER’S
REPRESENTATIONS AND WARRANTIES</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>By
concluding the Agreement, Customer represents, warrants and undertakes to
CODEFRAME, that each of the following representations and warranties is true,
accurate and not misleading on the Effective Date (as defined below).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.3dy6vkm"></a><b><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>(A)<span style='font:7.0pt "Times New Roman"'> </span></span></b><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer's
awareness of Whispr Apps and Services</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
has read and understood this Agreement, which is available on CODEFRAME’s
website at</span><span lang=EN-GB> <a href="http://www.whispr.online/terms"><span
style='font-size:11.0pt;font-family:"Cambria",serif;color:#0563C1'>www.whispr.online/terms</span></a></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'> (“<b>CODEFRAME
Website</b>”), the Data Protection Addendum available on CODEFRAME Website and
the privacy policy available on CODEFRAME Website (“<b>Privacy Policy</b>”) and
any other terms applicable to the Subscription and Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
has sufficient understanding of the functionality, usage, and other material
characteristics of Whispr Apps and Services, cloud technology and cloud-based
software systems to understand this Agreement and to appreciate the risks and
implications of purchasing features/functionalities/a Subscription of/to the
Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
appreciates the risks and implications of accessing and/or using the Whispr
Apps and Services, and has the knowledge of managing them, and Customer is
solely responsible for any evaluations based on such knowledge.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
has obtained sufficient information about the Whispr Apps and Services to make
an informed decision to purchase a subscription to the Whispr Apps and
Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(B)<span
style='font:7.0pt "Times New Roman"'> </span></span></b><b><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>Authority to enter into
Agreement</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
has all requisite power and authority to enter into this Agreement, to purchase
a feature/functionality/subscription of/to the Whispr Apps and Services, to use
the Whispr Apps and Services and to carry out and perform his obligations under
this Agreement.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.1t3h5sf"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
is of sufficient age and capacity to purchase a
feature/functionality/subscription to the Whispr Apps and Services in
accordance with the laws and regulations of the Customer’s country of
incorporation or residence or any other applicable requirement.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(C)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Compliance with
applicable laws and regulations</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.4d34og8"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>The
entering into and performance of this Agreement will not result in any
violation of, be in conflict with, or constitute a material default under, with
or without the passage of time or the giving of notice:</span></p>

<p class=MsoNormal style='margin-top:4.0pt;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>any
provision of Customer’s constituent documents, if applicable;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>any
provision of any judgement, decree or order to which Customer is a party, by
which it is bound, or to which any of its material assets are subject;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>any
material agreement, obligation, duty or commitment to which Customer is a party
or by which it is bound;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
4.0pt;margin-left:85.05pt;text-indent:-28.35pt;page-break-after:avoid'><span
lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;color:black'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>any
laws, regulations or rules applicable to Customer.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(D)<span
style='font:7.0pt "Times New Roman"'> </span></span></b><b><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>Forbidden Jurisdiction</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
is solely obliged and responsible to verify before accessing or using the
Whispr Apps and Services whether in its use of Whispr Apps and Services comply
with all export and import laws and regulations and to comply with such
restrictions and limitations. Customer shall not access or use the Whispr Apps
and Services if Customer is located in any jurisdiction in which the provision
of the Whispr Apps and Services is prohibited under the laws of the United
States or the European Union or other applicable laws or regulations (&quot;<b>Forbidden
Jurisdictions</b>&quot;) and Customer shall not provide access to the Whispr
Apps and Services to any government, entity or individual located in any
Forbidden Jurisdiction. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.10<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
represents and warrants that (i) Customer is not prohibited from receiving
exports from U.S., E.U. or from any other country in which a Whispr’s Affiliate
is headquartered; (ii) Customer is not a national of, or a company registered
in, any Forbidden Jurisdiction; (iii) Customer is not listed on any government
list of a prohibited or restricted parties or located in a country that is
subject to a U.S. government embargo or that has been designated by the U.S.
government as a “terrorist supporting” country; and (iv) Customer shall comply
with all applicable export laws regarding the transmission of technical data.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>3.11<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
represents and warrants that is solely responsible for the accuracy, content
and legality of all Customer Data (as defined below).</span></p>

<h1 style='margin-top:.25in;margin-right:56.9pt;margin-bottom:4.0pt;margin-left:
.4in'><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>V.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>NO
CODEFRAME REPRESENTATIONS AND WARRANTIES</span></h1>

<p class=MsoNormal style='margin-left:.4in;text-indent:-.25in'><a
name="_heading=h.2s8eyo1"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CUSTOMER
HEREBY ACKNOWLEDGES AND AGREES THAT THE WHISPR APPS AND SERVICES ARE SOLD ON AN
&quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND &quot;WITH ALL FAULTS&quot;
BASIS AND CUSTOMER PURCHASES A FEATURE/FUNCTIONALITY/SUBSCRIPTION OF/TO THE
WHISPR APPS AND SERVICES EXCLUSIVELY AT HIS OWN RISK WITHOUT ANY EXPRESS OR
IMPLIED REPRESENTATIONS AND/OR WARRANTIES OF ANY KIND BY CODEFRAME, ITS
AFFILIATES, ITS RESELLERS OR ITS LICENSORS (IF ANY).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.17dp8vu"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>TO
THE FULLEST EXTENT PERMITTED BY LAW, CODEFRAME AND ITS AFFILIATES EXPRESSLY
DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES AND REPRESENTATIONS AS TO THE
WHISPR APPS AND SERVICES. TO THE FULLEST EXTENT PERMITTED BY LAW, NONE OF
CODEFRAME OR ITS AFFILIATES MAKES ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR
IMPLIED, (I) WITH REGARD TO THE WHISPR APPS AND SERVICES, INCLUDING WITHOUT
LIMITATION WITH REGARD TO THEIR PERFORMANCE, AVAILABILITY, COVERAGE,
UNINTERRUPTED AVAILABILITY, SECURITY, PRICING OR OPERATION, (II) THAT THE
WHISPR APPS AND SERVICES OR THE SERVERS THAT MAKES THEM AVAILABLE ARE FREE OF
VIRUSES, WORMS, TROJAN HORSES OR OTHER HARMFUL COMPONENTS, (III) THAT ANY
WHISPR APP AND SERVICE, ITS CONTENT AND ANY PRODUCT PROVIDED THROUGH IT ARE
ERROR-FREE OR THAT DEFECTS IN THE WHISPR APPS AND SERVICES, THEIR CONTENT WILL
BE CORRECTED.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>VI.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>LIMITATION
OF LIABILITY </span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>TO
THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL CODEFRAME OR ITS
AFFILIATES BE LIABLE FOR ANY CLAIMS, DAMAGES, LIABILITIES, LOSSES, COSTS OR
EXPENSES OF ANY KIND, WHETHER DIRECT OR INDIRECT, CONSEQUENTIAL, COMPENSATORY,
INCIDENTAL, ACTUAL, EXEMPLARY, PUNITIVE OR SPECIAL (INCLUDING DAMAGES FOR LOSS
OF BUSINESS, REVENUES, PROFITS, DATA, USE, GOODWILL OR OTHER INTANGIBLE USES)
REGARDLESS OF WHETHER CODEFRAME OR ITS AFFILIATES HAVE BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES, LIABILITIES, LOSSES, COSTS OR EXPENSES, ARISING
OUT OR IN CONNECTION WITH:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><a name="_heading=h.3rdcrjn"></a><span lang=EN-GB style='font-size:9.5pt;
font-family:"Cambria",serif;color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>THE
SALE OF WHISPR APPS AND SERVICES;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>THE
USE OR PERFORMANCE OF THE WHISPR APPS AND SERVICES;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>ANY
CONDUCT OR CONTENT OF ANY THIRD PARTY;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>ANY
UNLAWFUL ACCESS TO OR USE OF THE WHISPR APPS AND SERVICES;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
4.0pt;margin-left:85.05pt;text-indent:-28.35pt;page-break-after:avoid'><span
lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;color:black'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>THE
PERFORMANCE OF THE WHISPR APPS AND SERVICES AND ANY FAULT, DELAYS,
INTERRUPTIONS OR LACK OF AVAILABILITY OF THE WHISPR APPS AND SERVICES, OR
PRODUCTS PROVIDED IN CONNECTION WITH WHISPR APPS AND SERVICES.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>TO
THE FULLEST EXTENT PERMITTED BY LAW, CUSTOMER DISCLAIMS ANY RIGHT OR CAUSE OF
ACTION AGAINST CODEFRAME OR ITS AFFILIATES OF ANY KIND IN ANY JURISDICTION THAT
WOULD GIVE RISE TO ANY DAMAGES WHATSOEVER.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.26in1rg"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CUSTOMER
UNDERSTANDS AND AGREES THAT IT IS CUSTOMER'S OBLIGATION TO ENSURE COMPLIANCE WITH
ANY LEGISLATION RELEVANT TO HIS COUNTRY OF DOMICILE CONCERNING PURCHASE AND USE
OF THE WHISPR APPS AND SERVICES AND THAT CODEFRAME OR ITS AFFILIATES SHOULD NOT
ACCEPT ANY LIABILITY FOR ANY ILLEGAL OR UNAUTHORIZED PURCHASE OR USE OF THE
WHISPR APPS AND SERVICES.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>5.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>TO
THE FULLEST EXTENT PERMITTED BY LAW, CUSTOMER RELEASES CODEFRAME AND ITS
AFFILIATES FROM RESPONSIBILITY, LIABILITY, CLAIMS, DEMANDS, AND/OR DAMAGES
(ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN
(INCLUDING, BUT NOT LIMITED TO, CLAIMS OF NEGLIGENCE), ARISING OUT OF OR
RELATED TO DISPUTES BETWEEN CUSTOMER AND CODEFRAME AND/OR ITS AFFILIATES AND
THE ACTS OR OMISSIONS OF THIRD PARTIES.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>VII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>INDEMNIFICATION</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><a
name="_heading=h.lnxbz9"></a><span lang=EN-GB style='font-size:11.0pt;
font-family:"Cambria",serif'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>To
the fullest extent permitted by law, Customer will indemnify, defend and hold
harmless and reimburse CODEFRAME and/or its Affiliates from and against any and
all claims, demands, actions, damages, losses, costs and expenses (including
attorneys’ fees) incurred by CODEFRAME and/or its Affiliates arising from or
relating to: (i) purchase or use of the Whispr Apps and Services by Customer
and/or its Affiliates; (ii) Customer's responsibilities or obligations under
this Agreement or the Privacy Policy or any other applicable terms and
conditions; (iii) Customer's violation of this Agreement and/or the Privacy
Policy and/or any other applicable terms and conditions; (iv) Customer’s
violation of any rights of any other person or entity; and/or (v) any act or
omission of Customer that is negligent, unlawful or constitutes wilful
misconduct.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>VIII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>FORCE
MAJEURE</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
and its Affiliates shall not be liable to Customer or to any other third party
for failure to perform in connection with any force majeure event, including
acts of God, labour disputes or other industrial disturbances, electrical,
telecommunications, hardware, software or other utility failures, software bugs
or weaknesses, earthquakes, storms, or other nature-related events, blockages,
embargoes, riots, acts or orders of government, acts of  terrorism or war,
technological change, changes in interest rates or other monetary conditions,
pandemics and epidemics.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>IX.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>GOVERNING
LAW AND DISPUTE RESOLUTION</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>This
Agreement will be governed by and construed and enforced in accordance with the
laws of Romania, without regard to conflict of law rules or principles that
would cause the application of the laws of any other jurisdiction.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
and Customer shall cooperate in good faith to resolve any dispute arising out
of or in connection with this Agreement, including any question regarding its
existence, validity, interpretation, breach or termination, and any
non-contractual obligation or other matter arising out of or in connection with
them (“<b>Disputes</b>”). If the Parties hereto are unable to resolve a Dispute
within 90 days of notice of such Dispute, the jurisdiction and venue for
actions shall be courts located in Romania and both Parties hereby submit to
the personal jurisdiction of such courts.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>X.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>TERM
AND TERMINATION OF AGREEMENT</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>This
Agreement enters into force on the date Customer accesses and/or uses and/or
purchases a subscription to the Whispr Apps and Services, and if Customer is
offered by Whispr Apps and Services a Free Trial Subscription on the date
Customer starts accessing and using any of the Whispr Apps and Services (each
of these dates being referred to as the “<b>Effective Date</b>”). </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Unless
otherwise stated in this Agreement, the Agreement expires on the date of
termination of Subscription Term. Whether Customer is offered a Free Trial
Subscription, the Agreement expires on the date of termination of free trial
period.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Either
Party may terminate these Terms of Service for cause if the other Party commits
a material breach of this Agreement (including, without limitation, a delay in
Customer's payment of any money due under this Agreement) and fails to cure
such breach within fifteen (15) days (or with respect to Customer's payment
failure, within ten (10) days of receipt of a notice of default from the
non-defaulting Party.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
may immediately terminate this Agreement on notice to Customer if:</span><span
lang=EN-GB> </span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(i)
CODEFRAME is requested or directed to do so by any competent court of law,
government authority, public agency, or law enforcement agency, or (ii) there
are reasonable grounds to believe that Customer (or its Affiliates, if the
case) breaches provisions of this Agreement or accesses or uses the Whispr Apps
and Services in a manner that is unlawful, fraudulent, abusive, or that
otherwise violates the applicable laws, or creates legal risk for Whispr Apps
and Services or presents a threat to the security of the Whispr Apps and
Services or other Customers.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>9.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Upon
termination of this Agreement:</span></p>

<p class=MsoNormal style='margin-top:4.0pt;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
shall immediately cease any and all use of and access to the Whispr Apps and
Services;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>to
the extent not prohibited by law, all Customer’s rights under this Agreement
immediately terminate;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
0in;margin-left:85.05pt;margin-bottom:.0001pt;text-indent:-28.35pt;page-break-after:
avoid'><span lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;
color:black'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>to
the fullest extent permitted by law, Customer is not entitled to a refund of
any amount paid, unless otherwise strictly provided herein; and</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:56.7pt;margin-bottom:
4.0pt;margin-left:85.05pt;text-indent:-28.35pt;page-break-after:avoid'><span
lang=EN-GB style='font-size:9.5pt;font-family:"Cambria",serif;color:black'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>to
the fullest extent permitted by law, Section III (Fees), Section IV (Customer’s
Representations and Warranties), Section VI (Limitation of Liability), Section
VII (Indemnification), Section IX (Governing Law and Dispute Resolution),
Section X (Term and Termination of Agreement), Section XII (Confidentiality),
Section XIII (Intellectual Property Rights) shall continue to apply in
accordance with their terms.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>XI.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>CUSTOMER
DATA and sensitive information</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>“<b>Customer
Data</b>” means any information of any type submitted or collected by or on
behalf of Customer, its Affiliates through Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer shall
own and retain all rights, titles and interests in and to the Customer Data.
This Agreement does not grant us any ownership rights to Customer Data.
Customer grants permission to CODEFRAME, its Affiliates and its licensors (if
any) to use the Customer Data only to the extent necessary to provide the
Whispr Apps and Services to Customer and its Affiliates and as otherwise
permitted by this Agreement. If you are using the Whispr Apps and Services on
behalf of a third party, then you represent and warrant that you have all
rights and permissions to do so.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer shall
ensure that the use of any and all Customer Data is compliant with Customer’s
privacy policies and all applicable laws, regulations and conventions,
including those related to data privacy and data transfer.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
specifically agrees not to use the Cloud Services to store, process or transmit
any Sensitive Information. “<b>Sensitive Information</b>” means: (i) special
categories of personal data indicated in Regulation (EU) 2016/679 of the
European Parliament and of the Council of 27 April 2016 (“<b>GDPR</b>”),
Article 9(1) or any successor or complementary legislation (ii) credit, debit
or other payment card data subject to the Payment Card Industry Data Security
Standards (“<b>PCI DSS</b>”); (iii) patient, medical or other protected health
information regulated by the Health Insurance Portability and Accountability
Act (“<b>HIPAA</b>”); (iv) other personal information subject to regulation or
protection under specific laws such as the Gramm-Leach-Bliley Act (“<b>GLBA</b>”)
(or related rules or regulations); (v) any other similar data that is protected
under applicable laws or regulations.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer will
not submit to the Whispr Apps and Services or use the Whispr Apps and Services
to collect any Sensitive Information.  </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Cloud Services
are not designed to comply with specific regulations including (but not limited
to) HIPAA, GLBA or Federal Information Security Management Act (“<b>FISMA</b>”).</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.7<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Customer
acknowledges that the Cloud Services are not designed to process or manage
Sensitive Information and agrees not to use the Cloud Services to collect,
manage or process Sensitive Information. </span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.8<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME, its
Affiliates or its licensors (if any) will not have any liability that may arise
from Customer’s use of the Whispr Apps and Services to collect, process or
manage Sensitive Information.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>10.9<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Additional
terms and conditions pertaining to the protection of data of Customers are
defined in the Data Protection Addendum (“<b>DPA</b>”) available on CODEFRAME’s
Website. </span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>XII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>CONFIDENTIALITY</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>11.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>In this
Agreement, “<b>Confidential Information</b>” shall mean any confidential
information, private or trade secrets, marked or not or in any other way
designated as confidential, in electronic or printed version or in any other
form, and include without limitation information not known by the general
public regarding Whispr Apps and Services, finance, current and potential
clients, traders’ identities, business and marketing plans, proposals,
projects, predictions, employees and compensations, drawings, guides,
inventions, patent applications, process and manufacturing information,
research plans and results, computer applications, databases, computer
software, flow charts, specifications, technical data, scientific and technical
information, test results, market researches, know-how relevant to any of the
above.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>11.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>All and any
Confidential Information disclosed by the disclosing Party to the receiving
Party is and will remain the property of the disclosing Party.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>11.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>During the Term
and to the extent not prohibited by law for an indefinite period thereafter,
each Party shall keep secret and will not without the prior written consent of
the other Party disclose to any person any confidential information relating to
the business or affairs of the other Party or its Affiliates which is learned
by the receiving Party pursuant to or otherwise in connection with the
Agreement, except insofar as such Confidential Information shall (otherwise
than by breach of the Agreement) be or come into the public domain or comes
lawfully into the possession of the receiving Party from a third party without
any obligation of confidentiality or which the receiving Party is required to
disclose by any law or governmental or regulatory.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>XIII.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>INTELLECTUAL
PROPERTY RIGHTS</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>12.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Through this
Agreement, Customers are permitted to access and use the Whispr Apps and
Services, and by this Agreement Customers are not granted a licence to any
software related to Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>12.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>“<b>Intellectual
Property Rights</b>” or “<b>IPR</b>” means all intellectual and industrial
property rights including (but not limited to) all patents, utility models,
rights to inventions, copyright and neighbouring and related rights (including
rights in software), moral rights, trademarks and service marks, logos,
business names, trade names, domain names, goodwill, rights in designs,
database rights, know-how, trade secrets, ideas, inventions, discoveries,
concepts, improvements to existing technology, processes and all other
intellectual property rights and/or proprietary rights, in each case whether
registered or unregistered and including all applications and rights to apply
for and be granted, renewals or extensions of, and rights to claim priority
from, such rights and all similar or equivalent rights or forms of protection that
subsist or will subsist now or in the future in any part of the world.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.25in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>12.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Whispr Apps and
Services are protected by intellectual property laws and by this Agreement
Customers are not granted any right, title, or interest with respect to the
Whispr Apps and Services or to any CODEFRAME Intellectual Property Rights.</span></p>

<h1><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif;
color:black'>XIV.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>MISCELLANEOUS</span></h1>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(A)<span
style='font:7.0pt "Times New Roman"'> </span></span></b><b><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>Entire Agreement</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>This
Agreement, the Privacy Policy, DPA (when applicable) and other documents that
might be published from time to time on the CODEFRAME Website constitutes the
entire agreement between Customer and CODEFRAME relating to access and use of
the Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Whether
this Agreement conflicts with the CODEFRAME Website or any other document
published from time to time on the CODEFRAME Website, this Agreement prevails.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>This
Agreement supersedes all prior or contemporaneous representations,
understandings, agreements, or communications between Customer and CODEFRAME or
its Affiliates, whether written or verbal, regarding the subject matter of this
Agreement.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(B)<span
style='font:7.0pt "Times New Roman"'> </span></span></b><b><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>Severability</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Should
any provision of this Agreement, or any provision incorporated into this
Agreement in the future, held to be void, invalid, unlawful or unenforceable,
then that provision will be limited or eliminated to the minimum extent
necessary, and the remaining provisions of these Terms of Use and Service will
remain in full force and effect.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(C)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Assignment of
Rights and Obligations</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
and its Affiliates may assign their rights and obligations under this Agreement
without Customer's consent.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(D)<span
style='font:7.0pt "Times New Roman"'> </span></span></b><b><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>No CODEFRAME waiver of
rights</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>CODEFRAME
or its Affiliates failure to exercise or enforce any right or provision of this
Agreement will not operate as a waiver of such right or provision and will not
limit CODEFRAME’s right to enforce such right or provision at a later time. All
waivers by CODEFRAME and/or its Affiliates must be unequivocal and in writing
to be effective.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(E)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Third Party
Rights</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Except
as otherwise provided herein, this Agreement is not intended to confer
third-party beneficiary rights upon any other person or entity.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.65in;margin-bottom:.0001pt;text-indent:-.25in'><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>(F)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Notices </span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>Any
notice, demand, consent or other communication (a “<b>Notice</b>”) given or
made under this Agreement must be in writing by a person duly authorised by the
sender, and must be delivered to the intended recipient by prepaid post (if
posted to an address in another country, by registered airmail), or by hand to
the address as will be given in writing by either Party to the other before
Customer subscribes to the Whispr Apps and Services.</span></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;
margin-left:.4in;margin-bottom:.0001pt;text-indent:-.4in'><span lang=EN-GB
style='font-size:11.0pt;font-family:"Cambria",serif'>13.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>A
Notice will be taken to be duly given or made: (i) in the case of delivery in
person, when delivered; (ii) in the case of delivery by post: (a) two (2)
Business Days after the date of posting (if posted to an address in the same
country); or (b) seven (7) Business Days after the date of posting (if posted
to an address in another country).</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>&nbsp;</span></p>

<h1><a name="_heading=h.bn8spu7mgmqo"></a><span lang=EN-GB style='font-size:
12.0pt;font-family:"Cambria",serif;color:black'>XV.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:12.0pt;font-family:"Cambria",serif'>OBJECTIONABLE
CONTENT AND ABUSIVE USERS</span></h1>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.1 CODEFRAME and its Affiliates are
committed to maintaining a safe and respectful environment for all users. We do
not tolerate objectionable content or abusive behaviour on our platform.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.2 Objectionable Content: Users are
prohibited from posting, sharing, or promoting content that is offensive,
harassing, discriminatory, hateful, threatening, or otherwise objectionable.
This includes, but is not limited to, content that is sexually explicit,
violent, or in violation of applicable laws and regulations.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.3 Abusive Users: Users are strictly
prohibited from engaging in any form of abusive behaviour, including but not
limited to harassment, bullying, impersonation, stalking, or any action that
creates a hostile or harmful environment for others.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.4 Reporting Violations: If you encounter
objectionable content or abusive behaviour on Whispr, we encourage you to
report it to us immediately. You can report such violations through the
reporting mechanisms provided within the platform.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.5 Actions Against Violators: CODEFRAME
and its Affiliates reserve the right to investigate reports of objectionable
content and abusive behaviour. Depending on the severity of the violation, we
may take various actions, including issuing warnings, suspending or restricting
user accounts, or permanently banning users who engage in such behaviour.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.6 No Refunds: In cases where a user's
account is suspended, restricted, or banned due to violation of this section,
no refund of fees or subscription charges will be provided.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.7 Compliance with Laws: Users must
comply with all applicable laws, rules, and regulations while using Whispr and
the associated services. Any violation of this Agreement may result in legal
action.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.8 User Responsibility: Users are
responsible for the content they post and their interactions with others on the
platform. By using Whispr, users agree to abide by these terms and conditions
and to contribute to a positive and respectful community.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>15.9 Changes to this Section: CODEFRAME and
its Affiliates reserve the right to update or modify this section at any time.
Users are encouraged to review this section regularly to stay informed about
our policies regarding objectionable content and abusive behaviour.</span></p>

<p class=MsoNormal style='margin-top:6.0pt'><span lang=EN-GB style='font-size:
11.0pt;font-family:"Cambria",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center'><span
lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>***</span></p>

<p class=MsoNormal><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>&nbsp;</span></p>

</div>

<span lang=EN-GB style='font-size:28.0pt;line-height:133%;font-family:"Cambria",serif;
color:red'><br clear=all style='page-break-before:auto'>
</span>

<div class=WordSection3>

<p class=MsoNormal align=center style='margin-top:6.0pt;text-align:center;
line-height:133%;border:none'><span lang=EN-GB style='font-size:28.0pt;
line-height:133%;font-family:"Cambria",serif;color:red'>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-GB style='font-size:11.0pt;font-family:"Cambria",serif'>&nbsp;</span></p>

</div>

</body>

</html>

`;
