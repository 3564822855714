import React from 'react';
import FAQSection from '../../sections/FAQSection/FAQSection';
import ContactSection from '../../sections/ContactSection/ContactSection';
import GoBackHeader from '../../organisms/GoBackHeader';

const Support = () => {
    return (
        <div>
            <GoBackHeader />
            <FAQSection />
            <ContactSection />
        </div>
    );
};

export default Support;
