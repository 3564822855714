import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextLogo } from '../../../assets/images';
const Success = () => {
    const [searchParams] = useSearchParams();

    return (
        <div className="contentWrapper">
            <img src={TextLogo} alt="Whispr Text Logo" width={200} />
            <h1 className="title">Congratulations!🎉</h1>
            <p className="description">
                You're one step away from synchronizing your Instagram account
                with Whispr.
            </p>
            <p className="description">
                Press <b>Return To App</b> to head back to the Whispr Mobile App
                and finish the synchronization process.
            </p>

            <a
                className="redirect"
                href={`whispr://Home?code=${searchParams.get('code')}`}
            >
                Return to App
            </a>
        </div>
    );
};

export default Success;
