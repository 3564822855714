import './Download.css';
import { isAndroid, isIOS, isMacOs } from 'react-device-detect';
import { useEffect } from 'react';

const Download = () => {
    const { REACT_APP_IOS_STORE_URL = '', REACT_APP_ANDROID_STORE_URL = '' } =
        process.env;

    useEffect(() => {
        if ((isIOS || isMacOs) && REACT_APP_IOS_STORE_URL) {
            window.location.href = REACT_APP_IOS_STORE_URL;
        } else if (isAndroid && REACT_APP_ANDROID_STORE_URL) {
            window.location.href = REACT_APP_ANDROID_STORE_URL;
        }
    });

    return (
        <div className={'download-container'}>
            <div className={'store-links-containers'}>
                <a
                    href={REACT_APP_IOS_STORE_URL}
                    className={'store-link apple-store-link'}
                ></a>

                <a
                    href={REACT_APP_ANDROID_STORE_URL}
                    className={'store-link google-store-link'}
                ></a>
            </div>
        </div>
    );
};

export default Download;
