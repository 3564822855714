import React from 'react';
import './FAQSection.css';
import CustomAccordion from '../../organisms/CustomAccordion';

const FAQSection = () => {
    const questionsAndAnswers = [
        {
            question: 'How can I sync my Instagram account with Whispr?',
            answer: 'To sync your Instagram account with Whispr and receive a verified badge, simply press the Instagram Sync button.'
        },
        {
            question:
                'Is it possible to customize the background of my Whispr room when sharing it on Instagram?',
            answer: 'Absolutely! Whispr allows you to choose from a variety of custom backgrounds or even upload your own image to make your room stand out on Instagram stories.'
        },
        {
            question: 'Are my questions and answers on Whispr private?',
            answer: 'Your privacy is our priority. By default, all questions and answers are anonymous. However, you can choose to reveal your username when sending a question.'
        },
        {
            question:
                'Can I purchase Whisps and boost my questions to get more visibility?',
            answer: 'Yes, you can buy Whisps from our in-app store to boost your questions and increase their visibility within a room.'
        },
        {
            question: 'What happens if I run out of Whisps?',
            answer: 'If you run out of Whisps, you can purchase more in the app to continue boosting your questions and enjoying all the features Whispr has to offer.'
        },
        {
            question: 'How do I create my own Whispr room?',
            answer: 'Creating a room on Whispr is easy. Simply navigate to the Rooms section, click "Create Room," and follow the prompts to set up your room, add a topic, and start receiving questions.'
        }
    ];
    return (
        <section className="faq-container">
            <section className="faq-join">
                <h1 className="faq-title">Frequently Asked Questions</h1>
            </section>
            <section className="faq-more">
                <div className="faq-header">
                    <div className="faq-divide"></div>

                    <div data-aos="fade-right" className="faq-heading">
                        <h2 className="faq-title1">
                            Quick answers to questions you may have. Can't find
                            what you're looking for? Send us a message by
                            completing the below form
                        </h2>
                    </div>
                </div>
                <div className="faq-accordions">
                    <CustomAccordion sections={questionsAndAnswers} />
                </div>
            </section>
        </section>
    );
};

export default FAQSection;
