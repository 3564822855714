import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextLogo } from '../../assets/images';
import { useNavigate } from 'react-router-dom';

const GoBackHeader = () => {
    const navigate = useNavigate();

    const handleGoHome = () => navigate('/');

    return (
        <div className="headerContainer">
            <IconButton
                sx={{ color: 'white' }}
                size="small"
                id="roomHomeIcon"
                onClick={handleGoHome}
            >
                <ArrowBackIcon />
            </IconButton>
            <img
                alt="whispr-logo"
                src={TextLogo}
                className={'logo'}
                onClick={handleGoHome}
            />
        </div>
    );
};

export default GoBackHeader;
