import React from 'react';
import DOMPurify from 'dompurify';
import { termsAndConditions } from './TermsAndConditions.content';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div
            className="privacy-policy-container"
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(termsAndConditions)
            }}
        ></div>
    );
};

export default TermsAndConditions;
