import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Room from './components/pages/Room/Room';
import Success from './components/pages/Success/Success';
import Home from './components/pages/Home/Home';
import Support from './components/pages/Support/Support';
import DeleteAccount from './components/pages/DeleteAccount/DeleteAccount';
import ScrollToTop from './components/organisms/ScrollToTop';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/pages/TermsAndConditions/TermsAndConditions';
import Download from './components/pages/Download/Download';

function App() {
    const router = [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/download',
            element: <Download />
        },
        {
            path: '/applinks',
            element: <Download />
        },
        {
            path: '/support',
            element: <Support />
        },
        {
            path: '/deleteAccount',
            element: <DeleteAccount />
        },
        {
            path: '/rooms/:room',
            element: <Room />
        },
        {
            path: '/sync/success',
            element: <Success />
        },
        {
            path: '/terms',
            element: <TermsAndConditions />
        },
        {
            path: '/privacyPolicy',
            element: <PrivacyPolicy />
        },
        {
            path: '*',
            element: <Navigate to={'/'} replace={true} />
        }
    ];

    return (
        <BrowserRouter>
            <Routes>
                {router.map((route) => (
                    <Route path={route.path} element={route.element} />
                ))}
            </Routes>
            <ScrollToTop />
        </BrowserRouter>
    );
}

export default App;
