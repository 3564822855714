import React, { useEffect } from 'react';

import './Navbar.css';
import { HamburgerIcon, TextLogo } from '../../assets/images';
import { Link as RedirectLink } from 'react-router-dom';
import { Link as SinglePageLink } from 'react-scroll';
import { getAppStoreLink } from '../../utilities/hooks';

const Navbar = (props: INavbarProps) => {
    const listenForUrlChangesMobileMenu = () => {
        let url = window.location.href;
        document.body.addEventListener(
            'click',
            () => {
                requestAnimationFrame(() => {
                    if (url !== window.location.href) {
                        runMobileMenuCodeEmbed();
                        url = window.location.href;
                    }
                });
            },
            true
        );
    };

    const runMobileMenuCodeEmbed = () => {
        const handleOpenMenu = () => {
            mobileMenu.style.transform = 'translateX(0%)';
        };
        const handleCloseMenu = () => {
            mobileMenu.style.transform = 'translateX(100%)';
        };
        // Mobile menu
        const mobileMenu = document.querySelector(
            '#mobile-menu'
        ) as HTMLElement;

        // Buttons
        const closeButton = document.querySelector('#close-mobile-menu');
        const openButton = document.querySelector('#open-mobile-menu');

        // On openButton click, set the mobileMenu position left to -100vw
        if (openButton && mobileMenu)
            openButton.addEventListener('click', handleOpenMenu);

        // On closeButton click, set the mobileMenu position to 0vw
        if (closeButton && mobileMenu)
            closeButton.addEventListener('click', handleCloseMenu);

        return function cleanupListener() {
            openButton?.removeEventListener('click', handleOpenMenu);
            closeButton?.removeEventListener('click', handleCloseMenu);
        };
    };

    useEffect(() => {
        runMobileMenuCodeEmbed();
        listenForUrlChangesMobileMenu();
        // eslint-disable-next-line
    }, []);

    const handleCloseMobileMenu = () => {
        const mobileMenu = document.querySelector(
            '#mobile-menu'
        ) as HTMLElement;
        mobileMenu.style.transform = 'translateX(100%)';
    };

    const renderNavItems = () =>
        props.options?.map(({ linkToSection, linkToPage, name }) =>
            linkToSection ? (
                <SinglePageLink
                    key={`navbar-link-${name}`}
                    className="navbar-link nav-link"
                    to={linkToSection}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={handleCloseMobileMenu}
                >
                    {name}
                </SinglePageLink>
            ) : (
                linkToPage && (
                    <RedirectLink
                        key={`navbar-link-${name}`}
                        to={linkToPage}
                        className="navbar-link nav-link"
                        onClick={handleCloseMobileMenu}
                    >
                        {name}
                    </RedirectLink>
                )
            )
        );

    return (
        <nav className={`navbar-navbar ${props.rootClassName} `}>
            <img
                alt="whispr-logo"
                src={TextLogo}
                className="navbar-branding-logo"
            />
            <div className="navbar-nav-content">
                <div className="navbar-nav-links">{renderNavItems()}</div>
                <RedirectLink
                    to={getAppStoreLink()}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="get-started navbar-get-started"
                >
                    <span className="navbar-text">Download now</span>
                </RedirectLink>
                <div
                    id="open-mobile-menu"
                    className="navbar-hamburger get-started"
                >
                    <img
                        alt={props.image_alt}
                        src={props.image_src}
                        className="navbar-image"
                    />
                </div>
            </div>
            <div id="mobile-menu" className="navbar-mobile-menu">
                <div className="navbar-branding">
                    <img
                        alt={props.image_alt1}
                        src={props.image_src1}
                        className="navbar-image1"
                    />
                    <div id="close-mobile-menu" className="navbar-container">
                        <svg viewBox="0 0 1024 1024" className="navbar-icon">
                            <path
                                d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"
                                className=""
                                fill="#fff"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="navbar-nav-links1">{renderNavItems()}</div>
                <RedirectLink
                    to={getAppStoreLink()}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="get-started"
                >
                    <span className="navbar-text1">Download now</span>
                </RedirectLink>
            </div>
        </nav>
    );
};

Navbar.defaultProps = {
    image_alt: 'image',
    image_src1: TextLogo,
    image_src: HamburgerIcon,
    image_alt1: 'image',
    rootClassName: ''
};

interface INavbarProps {
    options: {
        id: number;
        name: string;
        linkToSection?: string;
        linkToPage?: string;
    }[];
    image_alt: string;
    image_src1: string;
    image_src: string;
    image_alt1: string;
    rootClassName: string;
}

export default Navbar;
