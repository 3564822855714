import React, { useState } from 'react';

import Navbar from '../../organisms/Navbar';
import './Home.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Footer } from '../../organisms/Footer';
import { HomeSection } from '../../sections/HomeSection';
import { FeaturesSection } from '../../sections/FeaturesSection';
import { CTASection } from '../../sections/CTASection';
import { ArrowUp } from '../../../assets/images';
import { animateScroll as scroll } from 'react-scroll';

const Home = () => {
    const handleScrollTop = () => {
        scroll.scrollToTop({ duration: 200 });
    };

    const [options] = useState([
        { id: 1, name: 'Home', linkToSection: 'homeSection' },
        { id: 2, name: 'Features', linkToSection: 'featuresSection' },
        { id: 3, name: 'Join the community', linkToSection: 'CTASection' },
        { id: 4, name: 'FAQ', linkToPage: '/support' }
    ]);
    return (
        <div className="home-container">
            <Navbar
                options={options}
                rootClassName="navbar-root-class-name"
            ></Navbar>
            <HomeSection />
            <FeaturesSection />
            <CTASection />
            <Footer />
            <div
                className="scroll-top-container button"
                onClick={handleScrollTop}
            >
                <img className="scroll-top-icon" src={ArrowUp} alt="arrow-up" />
            </div>
        </div>
    );
};

export default Home;
