import React from 'react';
import {
    InstagramIcon,
    LinkedinIcon,
    TextLogo,
    TwitterIcon
} from '../../assets/images/index';
import { Link } from 'react-router-dom';

const {
    REACT_APP_LINKEDIN_URL = '',
    REACT_APP_INSTAGRAM_URL = '',
    REACT_APP_TWITTER_URL = ''
} = process.env;

enum NavigableFooterElements {
    TERMS = '/terms',
    PRIVACY = '/privacyPolicy',
    FAQ = '/support',
    DELETE = '/deleteAccount'
}

export function Footer() {
    return (
        <footer className="home-footer">
            <div className="home-content2">
                <main className="home-main-content">
                    <div className="home-content3">
                        <header className="home-main2">
                            <div className="home-header1">
                                <img
                                    alt="logo"
                                    src={TextLogo}
                                    className="home-branding"
                                />
                                <span className="home-text16">
                                    Find Your Voice, Anonymously Expressed
                                </span>
                            </div>
                            <div className="home-socials">
                                <a
                                    href={REACT_APP_LINKEDIN_URL}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="home-link"
                                >
                                    <img
                                        alt="linkedin"
                                        src={LinkedinIcon}
                                        className="social"
                                    />
                                </a>
                                <a
                                    href={REACT_APP_INSTAGRAM_URL}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="home-link1"
                                >
                                    <img
                                        alt="instagram"
                                        src={InstagramIcon}
                                        className="social"
                                    />
                                </a>
                                <a
                                    href={REACT_APP_TWITTER_URL}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="home-link2"
                                >
                                    <img
                                        alt="twitter"
                                        src={TwitterIcon}
                                        className="social"
                                    />
                                </a>
                            </div>
                        </header>
                        <header className="home-categories">
                            <div className="home-category">
                                <div className="home-header1">
                                    <span className="footer-header">
                                        Company
                                    </span>
                                </div>
                                <div className="home-links">
                                    <Link
                                        to={NavigableFooterElements.TERMS}
                                        className="footer-link"
                                    >
                                        Terms &amp; Conditions
                                    </Link>
                                    <Link
                                        className="footer-link"
                                        to={NavigableFooterElements.PRIVACY}
                                    >
                                        Privacy policy
                                    </Link>
                                    <span className="home-text24">
                                        Our entities:
                                        <li>CODEFRAME SRL</li>
                                        <li>CODE IGNITE SRL</li>
                                    </span>
                                </div>
                            </div>
                            <div className="home-category">
                                <div className="home-header1">
                                    <span className="footer-header">
                                        Contact
                                    </span>
                                </div>
                                <div className="home-links">
                                    <span className="footer-link">
                                        support@whispr.online
                                    </span>
                                </div>
                            </div>
                            <div className="home-category">
                                <div className="home-header1">
                                    <span className="footer-header">Other</span>
                                </div>
                                <div className="home-links">
                                    <Link
                                        className="footer-link"
                                        to={NavigableFooterElements.FAQ}
                                    >
                                        FAQ &amp; Support
                                    </Link>
                                    <Link
                                        className="footer-link"
                                        to={NavigableFooterElements.DELETE}
                                    >
                                        Account Deletion
                                    </Link>
                                </div>
                            </div>
                        </header>
                    </div>
                    <section className="home-copyright">
                        <span className="home-text24">
                            © 2023 Whispr. All Rights Reserved.
                        </span>
                    </section>
                </main>
            </div>
        </footer>
    );
}
