import React from 'react';
import { MockupHomeScreen } from '../../assets/images/index';
import { getAppStoreLink } from '../../utilities/hooks';
import { Link as RedirectLink } from 'react-router-dom';
import { Link } from 'react-scroll';

export const HomeSection = () => {
    return (
        <section className="home-section" id="homeSection">
            <div className="home-image">
                <img
                    alt="mockup-homescreen"
                    src={MockupHomeScreen}
                    className="home-image1"
                />
            </div>
            <div className="home-hero">
                <div className="home-content">
                    <main className="home-main">
                        <header className="home-header">
                            <h1 className="home-heading">
                                Whispr: Your Anonymous Question &amp; Answer
                                Platform
                            </h1>
                            <span className="home-caption">
                                <span>
                                    Welcome to Whispr, a unique and secure
                                    platform where you can ask and answer
                                    questions anonymously or with your username.
                                </span>
                                <br></br>
                                <br></br>
                                <span>
                                    Connect with others, share thoughts, and
                                    gain insights without revealing your
                                    identity. Join Whispr today and experience a
                                    new way to communicate.
                                </span>
                            </span>
                        </header>
                        <div className="home-buttons">
                            <RedirectLink
                                to={getAppStoreLink()}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="home-get-started button"
                            >
                                <span className="home-text04">
                                    Download now
                                </span>
                            </RedirectLink>

                            <Link
                                to="featuresSection"
                                className="home-get-started1 button"
                            >
                                <span className="home-text05">
                                    View features
                                </span>
                            </Link>
                        </div>
                    </main>
                </div>
                <div className="home-image2">
                    <img
                        alt="mockup-homescreen"
                        src={MockupHomeScreen}
                        className="home-image3"
                    />
                </div>
            </div>
        </section>
    );
};
