import React from 'react';
import { MockupAnswerQuestion } from '../../assets/images/index';
import { getAppStoreLink } from '../../utilities/hooks';
import { Link } from 'react-router-dom';

export function CTASection() {
    return (
        <section className="home-section2" id="CTASection">
            <section className="home-note">
                <div className="home-image4">
                    <img
                        alt="mockup"
                        src={MockupAnswerQuestion}
                        className="home-image5"
                    />
                </div>
                <div className="home-content1">
                    <div className="home-main1">
                        <div className="home-heading1">
                            <h2 className="section-heading">
                                Join Whispr today and revolutionize how you
                                interact with your community!
                            </h2>
                            <p className="section-description">
                                <span>
                                    Don&apos;t miss out on the chance to join a
                                    vibrant community of curious minds.
                                    Experience anonymous and engaging
                                    conversations, boost your questions to stand
                                    out, and connect like never before.
                                </span>
                                <br></br>
                                <br></br>
                                <span>
                                    Get Whispr now and start sharing your
                                    thoughts without limits.
                                </span>
                            </p>
                        </div>
                    </div>
                    <Link
                        to={getAppStoreLink()}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="download-button-link-wrapper"
                    >
                        <div className="download-button button">
                            <span className="home-text15">
                                Download for free
                            </span>
                        </div>
                    </Link>
                </div>
            </section>
        </section>
    );
}
