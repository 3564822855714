import React, {
    FormEvent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import './ContactSection.css';
import {
    Alert,
    Button,
    Collapse,
    IconButton,
    Modal,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CloseIcon from '@mui/icons-material/Close';
import {
    ContactSectionProps,
    SupportTicketTypes
} from '../../../utilities/interfaces';

const { REACT_APP_PUBLIC_ROOM_URL } = process.env;

const ContactSection = ({ type }: ContactSectionProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();
    const isRecaptchaAvailable = useMemo(
        () => !!executeRecaptcha,
        [executeRecaptcha]
    );

    const getRecaptchaToken = useCallback(
        async (event: string) => {
            if (!isRecaptchaAvailable) {
                console.log('Recaptcha not available');
                return;
            }
            return await executeRecaptcha?.(event);
        },
        [executeRecaptcha, isRecaptchaAvailable]
    );

    const sendMessage = async (recaptchaToken: string) => {
        try {
            const response = await axios.post(
                `${REACT_APP_PUBLIC_ROOM_URL}/support/${
                    type === SupportTicketTypes.DELETE_ACCOUNT
                        ? 'deleteAccount'
                        : 'create'
                }`,
                {
                    name,
                    email,
                    recaptchaToken,
                    ...(type === SupportTicketTypes.DELETE_ACCOUNT
                        ? { type }
                        : { message })
                }
            );
            if (response?.status === 200) {
                resetFormValues();
                return setOpen(true);
            }
            console.log('Error creating support ticket');
            setAlertOpen(true);
        } catch (err) {
            console.log('Error creating support ticket');
            setAlertOpen(true);
        }
    };
    const resetFormValues = () => {
        setName('');
        setEmail('');
        setMessage('');
        setAlertOpen(false);
    };

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const recaptchaToken = await getRecaptchaToken('supportTicket');
        if (recaptchaToken) await sendMessage(recaptchaToken);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isAlertOpen) {
            setTimeout(() => {
                setAlertOpen(false);
            }, 4000);
        }
    }, [isAlertOpen]);
    return (
        <section className="contact-subscribe">
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <div className="modalBox">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Thank you for your message! 🎉
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Your message was sent successfully. We'll get back to
                        you soon.
                    </Typography>

                    <Button
                        id="supportModalButton"
                        className={'enabledSubmitButton submitFormButton'}
                        variant="contained"
                        onClick={handleClose}
                    >
                        OK
                    </Button>
                </div>
            </Modal>
            <h2 className="contact-header">
                {type && type === SupportTicketTypes.DELETE_ACCOUNT
                    ? 'Want to delete your account? Complete the below form with your account\'s email address and we\'ll get back to you soon.'
                    : `Can't find what you're looking for? Send us a message by completing the
        below form`}
            </h2>
            <form className="contact-content" onSubmit={(e) => onFormSubmit(e)}>
                <div className="contact-inputs">
                    <input
                        maxLength={100}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        required
                        placeholder="Name *"
                        autoComplete="name"
                        className="contact-textinput input"
                    />
                    <input
                        maxLength={100}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                        placeholder="Email *"
                        autoComplete="email"
                        className="contact-textinput input"
                    />
                    {type !== SupportTicketTypes.DELETE_ACCOUNT && (
                        <textarea
                            maxLength={1400}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            placeholder="Message *"
                            className="contact-textinput textarea"
                        />
                    )}
                </div>
                <span className="contact-text">
                    {type && type === SupportTicketTypes.DELETE_ACCOUNT ? (
                        <>
                            We'll contact you at the provided email address to
                            confirm ownership of your account.
                            <br />
                            Please make sure that the provided email address is
                            the same one you used to create your account.
                            <br />
                            As a result of completing the deletion process, all
                            of your data will be removed from our data sources.
                            <br />
                            <br />
                        </>
                    ) : (
                        ''
                    )}
                    By submitting, you agree to receive email communications
                    from Whispr.
                    <br />
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a
                        href="https://policies.google.com/privacy"
                        className="text-link"
                    >
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://policies.google.com/terms"
                        className="text-link"
                    >
                        Terms of Service
                    </a>{' '}
                    apply.
                </span>
                {isAlertOpen ? (
                    <Collapse in={isAlertOpen}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {
                                'There was an error sending your message. Please try again later.'
                            }
                        </Alert>
                    </Collapse>
                ) : (
                    <button type="submit" className="contact-button button">
                        Send
                    </button>
                )}
            </form>
        </section>
    );
};

export default ContactSection;
