import React from 'react';
import Mark from '../organisms/Mark';
import {
    MockupAnswerQuestion,
    MockupAnsweredQuestions,
    MockupCreateRoom,
    MockupInstagramStory
} from '../../assets/images/index';
import { Carousel } from 'react-responsive-carousel';

export function FeaturesSection() {
    return (
        <section className="home-section1" id="featuresSection">
            <h2 className="home-text06">
                Discover Whispr&apos;s Powerful Features
            </h2>
            <div className="home-features">
                <Carousel
                    autoPlay
                    interval={4000}
                    showStatus={false}
                    showArrows={false}
                    infiniteLoop
                    preventMovementUntilSwipeScrollTolerance
                    swipeScrollTolerance={50}
                >
                    <header className={'home-feature feature'}>
                        <h3 className="home-text07">
                            Anonymity &amp; Username Flexibility
                        </h3>

                        <div className="feature-text-container">
                            <Mark Label="Ask questions anonymously or reveal your username - the choice is yours."></Mark>
                            <Mark Label="Connect with friends and followers using your Instagram handle as your username."></Mark>
                        </div>
                        <div className="carousel-image">
                            <img
                                alt="answered-questions-mockup"
                                src={MockupAnsweredQuestions}
                                className="home-image5"
                            />
                        </div>
                    </header>
                    <header className={'home-feature feature'}>
                        <h3 className="home-text09">
                            Instagram Stories Integration
                        </h3>

                        <div className="feature-text-container">
                            <Mark Label="Share your room and questions as stylish widgets on Instagram stories."></Mark>
                            <Mark Label="Customize backgrounds with pictures of your choice to enhance engagement."></Mark>
                        </div>
                        <div className="carousel-image">
                            <img
                                alt="instagram-story-mockup"
                                src={MockupInstagramStory}
                                className="home-image5"
                            />
                        </div>
                    </header>
                    <header className={'home-feature feature'}>
                        <h3 className="home-text08">Create Private Rooms</h3>

                        <div className="feature-text-container">
                            <Mark Label="Host your own private rooms where others can ask you questions."></Mark>
                            <Mark Label="Engage in meaningful discussions with your community."></Mark>
                        </div>
                        <div className="carousel-image">
                            <img
                                alt="create-room-mockup"
                                src={MockupCreateRoom}
                                className="home-image5"
                            />
                        </div>
                    </header>
                    <header className={'home-feature feature'}>
                        <h3 className="home-text10">
                            Boosted Questions with Whisps
                        </h3>

                        <div className="feature-text-container">
                            <Mark Label="Be the one that stands out in the room by boosting your questions with Whisps."></Mark>
                            <Mark Label="Increase visibility and get more responses to your inquiries by acquiring Whisps easily from the app."></Mark>
                        </div>
                        <div className="carousel-image">
                            <img
                                alt="answer-question-mockup"
                                src={MockupAnswerQuestion}
                                className="home-image5"
                            />
                        </div>
                    </header>
                </Carousel>
            </div>
        </section>
    );
}
