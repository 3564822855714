import { isAndroid, isIOS, isMacOs } from 'react-device-detect';

const { REACT_APP_IOS_STORE_URL = '', REACT_APP_ANDROID_STORE_URL = '' } =
    process.env;

export const getAppStoreLink = () => {
    if (isAndroid) return REACT_APP_ANDROID_STORE_URL;
    if (isIOS) return REACT_APP_IOS_STORE_URL;
    if (isMacOs) return REACT_APP_IOS_STORE_URL;
    return REACT_APP_ANDROID_STORE_URL;
};
