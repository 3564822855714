import React from 'react';
import DOMPurify from 'dompurify';
import { privacyPolicy } from './policy.content';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div
            className="privacy-policy-container"
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(privacyPolicy)
            }}
        ></div>
    );
};

export default PrivacyPolicy;
